/*
This is the environment we use locally for development, despite being named localHost instead of servicesINT.
This config points to local blob storage (instead of CDN over the cloud), and points to data on the MSG environment.
(ServicesINT points to CDN blob storage in the cloud as well as MS1, while auroraSIT points to CDN blob storage and MSG. )
*/

import { IPjMAppSettings } from "../interface/IPjMAppSettings";

export const localHost: IPjMAppSettings = {
    delegationTenant: "DEMO_TEN_1", // this is from fxp
    cdnBaseUrl: "http://localhost:5003",
    serviceEndPoints: [
        {
            serviceEndPoint: "https://professionalservicesint.microsoft.com/lmssit/",
            clientId: "8864560e-035f-4bef-a992-fac84af65259"
        },
        {
            serviceEndPoint: "https://projectservice-functions-sit-v3.azurewebsites.net/api",
            clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            serviceEndPoint: "https://professionalservicesint.microsoft.com/o2c/som/int/api",
            clientId: "901ae92d-f433-4d41-a6d8-37e080cace11"
        },
        {
            serviceEndPoint: "https://o2cpmssit-project-api-0.azurewebsites.net/api/",
            clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            serviceEndPoint: "https://projectservice-functions-sit-v2.azurewebsites.net/api",
            clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            serviceEndPoint: "https://purchaseorderservicessit.azure-api.net/SIT",
            clientId: "450ca928-b241-414e-8bc9-50b5ec00f52d"
        },
        {
            serviceEndPoint: "https://professionalservicesint.microsoft.com/o2c/purchaseorder-sit/api/v1",
            clientId: "01af0a61-6437-440a-8171-2628c2c91bf0"
        },
        {
            serviceEndPoint: "https://wwtkulstest.cloudapp.net:8081/api/v2/approval/laborentries/",
            clientId: "http://wwtkwebint"
        },
        {
            serviceEndPoint: "https://professionalservicesint.microsoft.com/gcm/grm/int/api",
            clientId: "38578c27-d821-45d3-95db-fcf0e8371e68"
        },
        {
            serviceEndPoint: "https://oneprofileuatapipack.azurefd.net",
            clientId: "99b7fa0a-a125-4f7a-abea-efb429bb8972"
        },
        {
            serviceEndPoint: "https://oneprofileuatcoreapi.microsoft.com",
            clientId: "99b7fa0a-a125-4f7a-abea-efb429bb8972"
        },
        {
            serviceEndPoint: "https://faultcontrolapie2e.azurewebsites.net",
            clientId: "38578c27-d821-45d3-95db-fcf0e8371e68"
        },
        {
          serviceEndPoint: "https://api.hotfix.virtuoso.microsoft.com/core/v1/",
            clientId: "api://d6b94c54-73b0-4e03-8aa0-f1ef0a7f58c4"
        },
        {
            serviceEndPoint: "https://sapwstst.microsoft.com/DGG/MSPSMDG/",
            clientId: "https://tSAPWSAADApp"
        },
        {
            serviceEndPoint: "https://professionalservicesint.microsoft.com/expensestore/sit/api",
            clientId: "c7ce0149-e139-41fa-a42b-2e72f5cf928b"
        },
        {
            serviceEndPoint: "https://graph.windows.net",
            clientId: "00000002-0000-0000-c000-000000000000"
        },
        {
            serviceEndPoint: "https://cpsupipoc-dev.microsoft.com/",
            clientId: "e732df60-9820-4895-acf7-692fd9aef75f"
        },
        {
            serviceEndPoint: "https://azesapsi2bldb1.redmond.corp.microsoft.com:8443/BOE/OpenDocument/",
            clientId: "37ceff22-5f2e-41ed-8ed7-b687a820d5b6"
        },
        {
            serviceEndPoint: "https://delegation-api-ppe.azurefd.net/",
            clientId: "6f40053e-5319-40e5-a90b-6f714506d96d"
        },
        {
            serviceEndPoint: "https://professionalservicesint.microsoft.com/time-core/api/",
            clientId: "5eb170f9-f828-4121-936c-288eb43b050e"
        },
        {
            serviceEndPoint: "https://flighting-api-preprod.azurefd.net",
            clientId: "6f40053e-5319-40e5-a90b-6f714506d96d"
        },
        {
            serviceEndPoint: "https://pslakedsdev.azurewebsites.net/pjm/api",
            clientId: "c5779b49-c238-45cb-8974-36958d4c7d96"
        },
        {
            serviceEndPoint: "https://ps-cluster-aks-sit.westus.cloudapp.azure.com/grm/sit/integrations/api",
            clientId: "38578c27-d821-45d3-95db-fcf0e8371e68"
        },
        {
            serviceEndPoint: "https://esxp-sit.microsoft.com/rmavl/api",
            clientId: "6f40053e-5319-40e5-a90b-6f714506d96d"
        },
        {
            serviceEndPoint: "https://esxp-sit.azurefd.net/grm",
            clientId: "38578c27-d821-45d3-95db-fcf0e8371e68"
        },
        {
            serviceEndPoint: "https://esxp-sit.azurefd.net/request",
            clientId: "38578c27-d821-45d3-95db-fcf0e8371e68"
        },
        {
            serviceEndPoint: "https://forecastservice-sit.azurewebsites.net/api/",
            clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            serviceEndPoint: "https://plan-sit.azurewebsites.net/api/v1/",
            clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        }
    ],
    featureFlags: [
        {
            name: "MisalignedAmendments",
            enabled: true
        },
        {
            name: "Pubsec",
            enabled: false
        },
        {
            name: "ShowLastUpdatedColumn",
            enabled: false
        },
        {
            name: "ShowUSPubSec",
            enabled: true
        },
        {
            name: "ShowExpenseReportsInKeyActions",
            enabled: true
        },
        {
            name: "ShowClinSlinFunding",
            enabled: true
        },
        {
            name: "DisableAlertsEmailNotifications",
            enabled: true
        },
        {
            name: "hidePinnedEntitiesInPortfolio", // rename this and swap the enabled to make more sense
            enabled: true
        },
        {
            name: "showFinancialsTabPortfolio",
            enabled: true
        },
        {
            name: "enableResourceScheduleFlow",
            enabled: true
        },
        {
            name: "enableFcrResourceLocationAndRoleEdit",
            enabled: true
        },
        {
            name: "showMSFederalEngagements",
            enabled: true
        },
        {
            name: "enableEngagementMinDateValidation",
            enabled: false
        },
        {
            name: "useNewPoEndPoint",
            enabled: false
        },
        {
            name: "enableFinancialsExport",
            enabled: true
        },
        {
            name: "enableBulkMaintenance",
            enabled: true
        },
        {
            name: "showOPLevel2Skills",
            enabled: true
        },
        {
            name: "deleteCSATContact",
            enabled: true
        },
        {
            name: "ecifIoExtension",
            enabled: true
        },
        {
            name: "enableMXDRChanges",
            enabled: true
        },
        {
            name: "enableComplexUnitsFeature",
            enabled: true
        },
        {
            name: "enableProjectClosureFeature",
            enabled: true
        }
    ],
    /* Since we locally run on SIT, the following partner end points are from our dmConfig_SIT, not our dmConfig */
    partnerEndpoints: [
        {
            Endpoint: "https://professionalservicesint.microsoft.com/lmssit/api/v1.1",
            ResourceId: "8864560e-035f-4bef-a992-fac84af65259"
        },
        {
            Endpoint: "https://professionalservicesint.microsoft.com/o2c/som/int/api",
            ResourceId: "901ae92d-f433-4d41-a6d8-37e080cace11"
        },
        {
            Endpoint: "https://o2cpmssit-project-api-0.azurewebsites.net/api/v1.0",
            ResourceId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            Endpoint: "https://o2cpmssit-project-api-0.azurewebsites.net/api/v2.0",
            ResourceId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            Endpoint: "https://purchaseorderservicessit.azure-api.net/SIT",
            ResourceId: "450ca928-b241-414e-8bc9-50b5ec00f52d"
        },
        {
            Endpoint: "https://professionalservicesint.microsoft.com/o2c/purchaseorder-sit/api/v1",
            ResourceId: "01af0a61-6437-440a-8171-2628c2c91bf0"
        },
        {
            Endpoint: "https://wwtkulstest.cloudapp.net:8081/api/v2/approval/laborentries/PendingApprovalCount/",
            ResourceId: "http://wwtkwebint"
        },
        {
            Endpoint: "https://professionalservicesint.microsoft.com/gcm/grm/int/api",
            ResourceId: "38578c27-d821-45d3-95db-fcf0e8371e68"
        },
        {
            Endpoint: "https://oneprofileuatapipack.azurefd.net",
            ResourceId: "99b7fa0a-a125-4f7a-abea-efb429bb8972"
        },
        {
            Endpoint: "https://faultcontrolapie2e.azurewebsites.net",
            ResourceId: "38578c27-d821-45d3-95db-fcf0e8371e68"
        },
        {
          Endpoint: "https://api.hotfix.virtuoso.microsoft.com/core/v1/",
            ResourceId: "api://d6b94c54-73b0-4e03-8aa0-f1ef0a7f58c4"
        },
        {
            Endpoint: "https://sapwstst.microsoft.com/DGG/MSPSMDG/",
            ResourceId: "https://tSAPWSAADApp"
        },
        {
            Endpoint: "https://delegationplatform-sit-eus.azurewebsites.net/api/delegations/",
            ResourceId: "6f40053e-5319-40e5-a90b-6f714506d96d"
        },
        {
            Endpoint: "https://esxp-sit.microsoft.com/rmavl/api",
            ResourceId: "6f40053e-5319-40e5-a90b-6f714506d96d"
        },
        {
            Endpoint: "https://forecastservice-sit.azurewebsites.net/api/",
            ResourceId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            Endpoint: "https://plan-sit.azurewebsites.net/api/v1/",
            ResourceId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        }
    ],
};
