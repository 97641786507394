/*
This is the environment that is deployed to UAT. (This is typically a gated release and must be approved manually before deployment happens.
This is typically done on the master branch.)
This points to data at MST and has CDN blob storage on the cloud.
*/

import { IPjMAppSettings } from "../interface/IPjMAppSettings";
export const uat: IPjMAppSettings = {
    delegationTenant: "ES", // this is from fxp
    cdnBaseUrl: "https://PJMUAT-esfacmanbaahe2c5.b01.azurefd.net/pjmux-angular",
    serviceEndPoints: [
        {
            clientId: "fbb51f92-bfef-472c-97b4-e68f8eb1a145",
            serviceEndPoint: "https://professionalservicesint.microsoft.com/lmsuat/"
        },
        {
            clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4",
            serviceEndPoint: "https://projectservices-api-uat.z01.azurefd.net/api/"
        },
        {
            clientId: "901ae92d-f433-4d41-a6d8-37e080cace11",
            serviceEndPoint: "https://professionalservicesint.microsoft.com/o2c/som/uat/api"
        },
        {
            serviceEndPoint: "https://projectservices-api-uat.z01.azurefd.net/functions/api",
            clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            clientId: "726cdb88-89c3-4dd7-a76e-629ab7c2ce8e",
            serviceEndPoint: "https://purchaseorderservicesbprt.azure-api.net/PurchaseOrderDetails"
        },
        {
            clientId: "a3bb3f9c-915f-4b03-98a0-50ff35c47936",
            serviceEndPoint: "https://professionalservicesint.microsoft.com/o2c/pouat/api/v1"
        },
        {
            clientId: "https://microsoft.onmicrosoft.com/c768264b-280a-4f7a-964d-4cb154793313",
            serviceEndPoint: "https://wwtkdmuat.trafficmanager.net/api/v2/approval/laborentries/"
        },
        {
            clientId: "778329d6-a980-4f3e-bf02-7bc44a384e15",
            serviceEndPoint: "https://esxp-uat.azurefd.net/grm/rm/"
        },
        {
            clientId: "778329d6-a980-4f3e-bf02-7bc44a384e15",
            serviceEndPoint: "https://faultcontrolapiuat.trafficmanager.net/"
        },
        {
            clientId: "api://5fbf287b-05fe-42f9-9dc4-bca8b0ec3e32",
          serviceEndPoint: "https://api.stage.virtuoso.microsoft.com/core/v1/"
        },
        {
            clientId: "1823642f-9e84-4426-a4ca-b8d41b415077",
            serviceEndPoint: "https://sapwstst.microsoft.com/DGT/MSPSMDG/"
        },
        {
            clientId: "c7ce0149-e139-41fa-a42b-2e72f5cf928b",
            serviceEndPoint: "https://professionalservicesint.microsoft.com/expensestore/uat/api"
        },
        {
            clientId: "00000002-0000-0000-c000-000000000000",
            serviceEndPoint: "https://graph.windows.net"
        },
        {
            clientId: "e732df60-9820-4895-acf7-692fd9aef75f",
            serviceEndPoint: "https://campusapi-uat.microsoft.com/"
        },
        {
            clientId: "7184ced4-4bd5-4482-968d-6f39b1515139",
            serviceEndPoint: "https://sapbobjblt-microsoft.msappproxy.net/BOE/OpenDocument/"
        },
        {
            clientId: "6f40053e-5319-40e5-a90b-6f714506d96d",
            serviceEndPoint: "https://delegationplatform-sit-eus.azurewebsites.net/api/delegations/"
        },
        {
            serviceEndPoint: "https://professionalservicesint.microsoft.com/time-core/api/",
            clientId: "5eb170f9-f828-4121-936c-288eb43b050e"
        },
        {
            serviceEndPoint: "https://pslakedsdev.azurewebsites.net/pjm/api",
            clientId: "c5779b49-c238-45cb-8974-36958d4c7d96"
        },
        {
            serviceEndPoint: "https://availabilityuat-wus.azurewebsites.net/api",
            clientId: "6f40053e-5319-40e5-a90b-6f714506d96d"
        },
        {
            serviceEndPoint: "https://forecastservice-uat.azurewebsites.net/api/",
            clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        }
    ],
    featureFlags: [
        {
            name: "MisalignedAmendments",
            enabled: true
        },
        {
            name: "Pubsec",
            enabled: false
        },
        {
            name: "ShowLastUpdatedColumn",
            enabled: false
        },
        {
            name: "ShowUSPubSec",
            enabled: true
        },
        {
            name: "ShowExpenseReportsInKeyActions",
            enabled: true
        },
        {
            name: "ShowClinSlinFunding",
            enabled: true
        },
        {
            name: "DisableAlertsEmailNotifications",
            enabled: true
        },
        {
            name: "hidePinnedEntitiesInPortfolio",
            enabled: true
        },
        {
            name: "showFinancialsTabPortfolio",
            enabled: true
        },
        {
            name: "enableResourceScheduleFlow",
            enabled: true
        },
        {
            name: "enableFcrResourceLocationAndRoleEdit",
            enabled: false
        },
        {
            name: "showMSFederalEngagements",
            enabled: true
        },
        {
            name: "enableEngagementMinDateValidation",
            enabled: false
        },
        {
            name: "useNewPoEndPoint",
            enabled: false
        },
        {
            name: "enableFinancialsExport",
            enabled: false
        },
        {
            name: "enableBulkMaintenance",
            enabled: true
        },
        {
            name: "showOPLevel2Skills",
            enabled: true
        },
        {
            name: "deleteCSATContact",
            enabled: true
        },
        {
            name: "ecifIoExtension",
            enabled: true
        },
        {
            name: "enableMXDRChanges",
            enabled: true
        },
        {
            name: "enableComplexUnitsFeature",
            enabled: true
        },
        {
            name: "enableProjectClosureFeature",
            enabled: true
        }
    ],
    partnerEndpoints: [
        {
            Endpoint: "https://professionalservicesint.microsoft.com/lmsuat/api/v1.1/",
            ResourceId: "fbb51f92-bfef-472c-97b4-e68f8eb1a145"
        },
        {
            Endpoint: "https://projectservices-api-uat.z01.azurefd.net/api/",
            ResourceId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            Endpoint: "https://projectservices-api-uat.z01.azurefd.net/functions/api",
            ResourceId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        },
        {
            Endpoint: "https://professionalservicesint.microsoft.com/o2c/som/uat/api",
            ResourceId: "901ae92d-f433-4d41-a6d8-37e080cace11"
        },
        {
            Endpoint: "https://purchaseorderservicesbprt.azure-api.net/PurchaseOrderDetails",
            ResourceId: "726cdb88-89c3-4dd7-a76e-629ab7c2ce8e"
        },
        {
            Endpoint: "https://professionalservicesint.microsoft.com/o2c/pouat/api/v1",
            ResourceId: "a3bb3f9c-915f-4b03-98a0-50ff35c47936"
        },
        {
            Endpoint: "https://wwtkdmuat.trafficmanager.net/api/v2/approval/laborentries/",
            ResourceId: "c768264b-280a-4f7a-964d-4cb154793313"
        },
        {
            Endpoint: "https://esxp-uat.azurefd.net/grm/rm/api",
            ResourceId: "778329d6-a980-4f3e-bf02-7bc44a384e15"
        },
        {
            Endpoint: "https://faultcontrolapiuat.trafficmanager.net/",
            ResourceId: "778329d6-a980-4f3e-bf02-7bc44a384e15"
        },
        {
          Endpoint: "https://api.stage.virtuoso.microsoft.com/core/v1/",
            ResourceId: "api://5fbf287b-05fe-42f9-9dc4-bca8b0ec3e32"
        },
        {
            Endpoint: "https://sapwstst.microsoft.com/DGT/MSPSMDG/",
            ResourceId: "1823642f-9e84-4426-a4ca-b8d41b415077"
        },
        {
            Endpoint: "https://delegationplatform-sit-eus.azurewebsites.net/api/delegations/",
            ResourceId: "6f40053e-5319-40e5-a90b-6f714506d96d"
        },
        {
            Endpoint: "https://availabilityuat-wus.azurewebsites.net/api",
            ResourceId: "6f40053e-5319-40e5-a90b-6f714506d96d"
        },
        {
            Endpoint: "https://forecastservice-uat.azurewebsites.net/api/",
            ResourceId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
        }
    ],
};
